@import "../../../styles/main";

$quiz-header-md-height: 64px;

.stickyHeader {
	position: fixed !important;
	top: 0;
	width: 100%;
}

.headerContainer {
	background: $night;
	height: $quiz-header-md-height !important;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	@include for-phone-only {
		height: 34px !important;
		display: block;
	}
	.sidebarToggleButton {
		border: none;
		color: $primary-color;
		outline: none;
		background: inherit;
		z-index: 1000;
		width: fit-content;
		padding: 0;
	}

	.buttonSection {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		gap: 20px;
	}

	.restartIcon {
		padding-right: 8px;
		padding-bottom: 2px;
	}
	.firstContainer {
		@extend .center-flex;
		.text {
			@extend .body-txt-lg;
			color: $white;
			line-height: 0px;
		}
	}
	.closeIcon {
		// position: absolute;
		// right: 0;
		height: $quiz-header-md-height !important;
		// border: 1px solid $night;
		width: 36px;
		// padding: 6px;
		cursor: pointer;
		// background: $white;
		@include for-phone-only {
			position: absolute;
			right: -2px;
			height: 34px !important;
		}
		// &:hover {
		//     transform: scale(1.1);
		// }
	}
	.quizBox {
		@include for-phone-only {
			padding: 0px 16px;
		}
	}
}

.AddButton {
	background: $uv-blue !important;
	color: $white !important;
	border: none !important;
	// &:hover {
	// 	background: $uv-blue !important;
	// }
	&:disabled {
		border: none;
		background-color: #f5f1f0 !important;
		color: #bab9b8 !important;
	}
}

.floatButton {
	width: 100%;
	@extend .center-flex;
	// position: fixed;
	// bottom: 30px;
	// left: 50%;
	// z-index: 9999;
	// transform: translateX(-50%);
	.cartButtonContainer {
		margin-top: 24px;
		margin-bottom: 24px;
		background-color: transparent;
		min-width: 250px;

		.addButton {
			width: 100%;
			background: $uv-blue !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $night;

			span {
				@extend .font-Neue-medium;
			}

			&:hover {
				background: $uv-blue !important;
			}
		}
	}
	@include for-phone-only {
		// width: calc(100% + 30px);
		// margin-left: -30px;
		// bottom: -1px;
		background-color: $night;
		// left: unset;
		// transform: none;
		.cartButtonContainer {
			margin-top: 12px;
			margin-bottom: 14px;
			width: calc(100% - 60px);
			.addButton {
				justify-content: space-between;
				padding: 0px 24px !important;
				border-color: $uv-blue;
			}
		}
	}
}

.productCount {
    @extend .text-light;
    width: 100%;
    @extend .body-txt-lg;
}
