@import "@ph/common/styles/main";

.quizHallmarkContainer {
	width: 100%;
	@include for-phone-only{
		margin-bottom: 30px;
	}
	.grid {
		flex: 0 0 calc(100% / var(--length));
		max-width: calc(100% / var(--length));

		@include for-phone-only {
			flex: 0 0 50%;
			max-width: 50%;
			border: 1px solid $night;
			border-top: none;
			border-left: none;
			&:nth-child(1),&:nth-child(3),&:nth-child(5),&:nth-child(7) {
				border-left: 1px solid $night;
			}
			&:nth-child(1),&:nth-child(2) {
				border-top: 1px solid $night;
			}
			
		}
		
	}
	.gridItem {
		background: transparent;
		border: 2px solid $night;
		border-left: none;
		padding: 12px 16px 12px 0px;
		text-align: left;
		display: flex;
		flex-direction: column;
		height: 100%;
        cursor: pointer;
		position: relative;
		&.first {
			border-left: 2px solid $night;
		}
        
		.icon {
			width: 100%;
			height: 154px;
			@extend .center-flex;
            padding: 0px 10px;

			
			svg {
				width: 52%;
			}
			&.large {
				height: 320px;
				svg {
					width: 56%;
				}

				@include for-phone-only {
					width: 100%;
    				height: 198px;
				}
			}
			@include for-phone-only {
				width: 100%;
    			height: 135px;
				padding-right: 0px;

			}
		}

		.title {
			@extend .note-1;
			@extend .font-Mono-medium;
			min-height: 45px;
			padding-left: 20px;
			&.large {
				min-height: 34px;
				display: flex;
				align-items: center;
			}
			@include for-phone-only {
				min-height: 35px;
			}
		}
		.subTitle {
			padding-left: 20px;
			min-height: 30px;
			@extend .note-2;
			@extend .font-Mono-medium;
		}
		&.selected {
            background: linear-gradient(180deg, #6283D8 0%, #FFFFFF 59.18%);
			.title,.subTitle {
				border-left: 8px solid $night;
				padding-left: 12px;

				@include for-phone-only {
					border-left: none;
					padding-left: 16px;
				}
			}
        }

		.label {
			position: absolute;
			padding: 3px 8px;
			border-radius: 50%;
			background-color: #bbc9ee;
			color: $night;
			border: 1px solid $night;
			right: 10px;
			@extend .note-2;
			@extend .font-Mono-medium;
			@include for-phone-only {
				padding: 3px 6px;
			}
		}

		@include for-phone-only {
			border: none;
			&.first {
				border: none;
			}
		}
	}
	
}
