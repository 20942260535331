@import "@ph/common/styles/main";

.PHQSelect {
	border: none;
	// border-bottom: 2px solid $primary-color;

	font-size: 26.5px;
	line-height: 39px;
	text-align: center;
	min-width: 250px;

	:global(.ant-select-selection-item) {
		padding-inline-end: 13px !important;
		height: auto;
		gap: 8px;
		border-radius: 0px;

		svg {
			fill: #000000;
		}

		@include for-phone-only {
			margin-left: -7px;
		}
	}
	:global(.ant-select-selection-item) {
		font-size: 26.5px;
		line-height: 39px;
		color: $uv-blue !important;
		@include for-phone-only {
			font-size: $h4-text-mobile;
			line-height: $h4-line-height-mobile;
		}
	}
	:global(.ant-select-selection-placeholder) {
		font-size: 26.5px;
		line-height: 39px;
		color: $uv-blue-40;
		@include for-phone-only {
			font-size: $h4-text-mobile;
			line-height: $h4-line-height-mobile;
		}
	}
	:global(.ant-select-selector) {
		box-shadow: none !important;
		border: none !important;
	}
	:global(.ant-select-single.ant-select-open .ant-select-selection-item) {
		color: $uv-blue !important;
	}
	:global(.ant-select-multiple){
		:global(.ant-select-selection-item){
			height: auto;
			gap: 4px
		}
	}
	:global(.ant-select-selection-item-content){
		margin-inline-end: 0px;
	}
	// &.ant-select-focused {
	//   border-color: red !important;
	// }
	&:focus {
		box-shadow: none;
	}

	:global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
		background-color: transparent;
		border: none;
		&:focus {
			box-shadow: none;
		}
	}

	&:global(.ant-select-open) {
		border-bottom: none !important;
	}

	@include for-phone-only {
		font-size: $h4-text-mobile;
		line-height: $h4-line-height-mobile;
		margin-bottom: 0px !important;
	}
}

.PHQSelectOption {
	border-radius: 0px !important;
	padding: 16px 12px !important;
	text-transform: uppercase;
	background-color: transparent;
	border-radius: 0px;
	// outline: 2px solid $night;
	background: #fff;
	box-shadow: none;
	:first-child {
		border-top: none;
	}
	@include for-phone-only {
		padding: 10px 12px !important;
	}
}
.PHQSelectPopup {
	border: 2px solid $night;
	// border-top: none !important;
	border-radius: 0px;
	background-color: transparent;
	padding: 0px;
	margin: 0px;
	box-shadow: none;
	:global(.ant-select-item-option-active) {
		background: linear-gradient(90deg, #d5def5 25%, #6283d8 100%);
		//    border-top: 1px solid $night;
	}
	:global(.ant-select-item-option-selected) {
		background: linear-gradient(90deg, #d5def5 25%, #6283d8 100%);
		border-radius: 0px;
		border: none;
		box-shadow: none;
		color: $night !important;
		border-left: 8px solid $night;
		padding-left: 4px !important;
		// border-top: 1px solid $night;
	}
	:global(.ant-select-item-option-content) {
		font-family: "PPNeueMontrealMono Medium" !important;
		font-size: $note-2-text;
		line-height: $note-2-line-height;
		text-transform: uppercase;
		color: $night !important;
		font-weight: 500;
		overflow: visible;
		white-space: pre-wrap;
	}
	:global(.rc-virtual-list-holder-inner){
		:first-child {
			border-top: none;
		}
	}
	:global(.ant-select-item) {
		text-align: center;
		padding-top: 14px;
		padding-bottom: 14px;
		border-radius: 0px;
		border-top: 2px solid $night;
		:first-child {
			border-top: none;
		}
	}

	@include for-phone-only {
		border-top: 2px solid $night;
		bottom: 0 !important;
		top: unset !important;
		width: 100% !important;
		left: 0 !important;
		height: auto !important;
		position: fixed !important;
		:global(.ant-select-item-option-content) {
			font-size: $note-2-text-mobile;
		}
	}
}

.selectInput {
	background-color: $white;
	box-shadow: none;
	outline: none !important;
	border: none !important;
	@extend .quiz-1;
	color: $uv-blue;
	&:focus {
		outline: none !important;
		border: none !important;
		box-shadow: none !important;
	}
	&:placeholder {
		display: none;
	}
}
