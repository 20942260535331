@import "@ph/common/styles/main";

.cartIcon {
	width: 90px;
	height: 64px;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	background-color: $uv-blue;
	transform: rotate(-90deg);
	position: fixed;
	right: -30px;
	top: 50%;
	cursor: pointer;
	z-index: 9999;

	.icon {
		position: absolute;
		top: 20%;
		left: 42%;
		transform: translate(-50%, -50%);
		transform: rotate(90deg);
	}
	.arrowIcon {
		@include for-phone-only {
			transform: rotate(360deg) !important;
		}
	}
	@include for-phone-only {
		display: none !important;
		left: -25px;
		right: unset;
		width: 55px;
		height: 34px;
		transform: rotate(90deg);
		bottom: 15px;
		top: unset;
		.icon {
			transform: rotate(-90deg);
			width: 15px;
			top: -5%;
        	left: 34%;
		}
	}
}

.cartDrawer {
	:global(.ant-drawer-body) {
		background: #f5f1f0;
		padding: 10px 50px 10px 50px;
		&::-webkit-scrollbar {
			width: 7px; // Width of the entire scrollbar
		}

		&::-webkit-scrollbar-track {
			background: transparent; // Color of the tracking area
		}

		&::-webkit-scrollbar-thumb {
			background: #8d8f91; // Color of the scroll thumb
			border-radius: 12px; // Rounded corners on the scroll thumb
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #8d8f91; // Color of the scroll thumb on hover
		}
		@include for-phone-only {
			padding: 10px 20px 20px 20px;
		}
	}
	:global(.ant-drawer-header-title) {
		justify-content: flex-end;
	}
	:global(.ant-drawer-header-title) {
		justify-content: flex-end;
		button {
			margin-right: 0px;
			img {
				width: 36px;
				height: 36px;
			}
			@include for-phone-only {
				padding: 0px;

				img {
					width: 30px;
					height: 30px;
					margin-right: -4px;
				}
			}
		}
		svg {
			color: $night;
		}
	}
	:global(.ant-drawer-header) {
		background: #f5f1f0;
		border-bottom: none;
		padding-bottom: 8px;
	}
}

.cartContainer {
	background-color: $white;
	width: 100%;
	// min-height: calc(100% - 120px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.cartItem {
		padding: 20px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid $uv-blue-40;
		margin: 0px 30px;


		@include for-phone-only {
			padding: 14px 0px;
			// padding-left: 20px;
			margin: 0px 16px;
		}

		&.md03Item {
			border-top: none;
			padding: 0px;
			margin-left: 0px;
			margin-right: 0px;
		}

		&.first {
			border-top: none !important;
		}

		&.subCartItem {
			margin-left: 40px;
			padding-left: 0px;
			padding-top: 12px;
			// padding: 10px 20px !important;
			@include for-phone-only {
				margin-left: 28px;
				padding-top: 6px;
			}
		}

		.cartTitle {
			color: $night;
			@extend .body-txt-lg;
			@extend .font-Neue-medium;
			@media (max-width: 1440px) {
				font-size: $note-1-text;
				line-height: $note-1-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-1-text-mobile;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemTitle,
		.itemPrice,
		.discountPrice {
			color: $uv-blue;

			@extend .note-1;
			@extend .font-Mono-medium;
			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
			&.bold {
				@extend .font-Mono-bold;
			}
		}
		.itemTitle {
			width: 75%;
			text-transform: uppercase;
			@extend .font-Mono-regular;
			svg {
				margin-left: 16px;
			}

			@include for-phone-only {
				svg {
					margin-left: 4px;
					width: 14px;
				}
			}
			@media (max-width: 370px) {
				width: 100%;
				font-size: 10px;
				display: flex;
				align-items: center;
				svg {
					margin-left: 3px;
					width: 12px;
				}
			}
		}

		// .itemPrice,
		// .discountPrice {
		// 	@extend .font-Mono-bold;
		// }

		.subItemTitle {
			color: $uv-blue;
			@extend .note-1;
			@extend .font-Mono-medium;
			width: 80%;

			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}

			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
		}
		.discountPrice {
			color: $uv-blue-40;
			text-decoration: line-through;
			@media (max-width: 1290px) {
				display: flex;
				justify-content: flex-end;
			}
		}
		.itemPrice {
			display: flex;
			gap: 0px;
			flex-wrap: wrap;
			justify-content: end;
		}

		.redDiscountPrice,
		.redItemPrice {
			font-style: italic;
			@extend .note-1;
			@extend .font-PP-italic;
			text-transform: none;
			color: $dusty;
			margin-left: 8px;

			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
			
		}
		.redDiscountPrice {
			text-decoration: line-through;
		}
	}
	.subTotal {
		padding: 4px 28px 30px 28px;
		background: #d5def5;

		.line {
			padding-bottom: 24px;
			border-top: 2px solid $night;
		}

		.disclaimer {
			display: inline-flex;
			gap: 8px;
			.icon {
				@extend .body-txt-lg;
				line-height: 17px;
				color: $night;
				margin-right: 6px;
			}
			.text {
				@extend .body-txt-md;
				color: $night;
			}
		}

		.subItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 6px 0px;

			.itemTitle,
			.itemPrice {
				color: $night;
				// text-transform: capitalize;
				@extend .note-1;
				@extend .font-Neue-medium;
			}
			.itemTitle {
				@extend .font-Mono-regular;
			}
		}

		@include for-phone-only {
			padding: 0px 16px 20px 16px;

			.line {
				padding-bottom: 16px;
			}
		}
	}
	.total {
		padding: 24px 28px 20px 28px;
		// border-left: 10px solid $uv-blue;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #d5def5;

		.itemTitle,
		.itemPrice,
		.discountPrice {
			color: $night;
			@extend .h6;
			@media (max-width: 1440px) {
				font-size: $body-text-mobile-lg;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemTitle,
		.itemPrice {
			@extend .font-Neue-medium;
		}

		.discountPrice {
			// color: $uv-blue-40;
			margin-right: 10px;
			text-decoration: line-through;
			color: #767472;
			// @media (max-width: 1290px) {
			// 	display: flex;
			// 	justify-content: flex-end;
			// }
		}

		@include for-phone-only {
			padding: 20px 16px 20px 16px;
		}
	}
}

.cartButtonContainer {
	margin-top: 24px;
	margin-bottom: 24px;
	background-color: $stone;
	// @extend .show-desktop-hide-mobile;
	.addButton {
		width: 100%;
		background: $uv-blue !important;
		display: flex;
		align-items: center;
		justify-content: end;

		span {
			@extend .font-Neue-medium;
		}

		&:hover {
			background: $uv-blue !important;
		}
	}

	@include for-phone-only {
		display: flex;
		justify-content: end;

		// .addButton {
		// 	width: calc(100% - 30px);
		// }
	}
}



.customCollapse {
		border: none;
		box-shadow: none;
		background-color: $white;
		// border-top: 1px solid $stone;
		border-bottom: none;
		border-radius: 0px;

		:global(.ant-collapse-header) {
			padding: 20px 0px !important;
			border-top: 1px solid $uv-blue-40;
			margin-left: 22px;
			margin-right: 30px;

			align-items: center !important;

			@include for-phone-only {
				padding: 16px 0px !important;
				margin-left: 12px;
    			margin-right: 16px;
			}
		}
		:global(.ant-collapse-item) {
			border: none;
			box-shadow: none;
			border-left: 8px solid transparent;

			@include for-phone-only {
				border-left: 4px solid transparent;
			}
		}
		:global(.ant-collapse-content) {
			border-top: none;
		}
		:global(.ant-collapse-expand-icon) {
			display: none !important;
			img {
				display: none !important;
			}
		}
		:global(.ant-collapse-content-box) {
			padding: 0px !important;

			div:first {
				border-top: none !important;
			}
		}
		:global(.ant-collapse-item-active) {
			border-left: 8px solid $uv-blue;
			border-radius: 0px !important;
			@include for-phone-only {
				border-left: 4px solid $uv-blue;
			}
		}
		:global(.ant-collapse-content-box) {
			padding: 0px !important;

			div {
				border-top: none !important;
			}
		}
	}

.priceContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	flex-wrap: wrap;
	justify-content: end;
}

.floatButton {
	width: 100%;
	@extend .center-flex;
	position: fixed;
	bottom: 30px;
	left: 50%;
	z-index: 9999;
	transform: translateX(-50%);
	display: none;
	.cartButtonContainer {
		margin-top: 24px;
		margin-bottom: 24px;
		background-color: transparent;
		min-width: 250px;

		.addButton {
			width: 100%;
			background: $uv-blue !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1px solid $night;

			span {
				@extend .font-Neue-medium;
			}

			&:hover {
				background: $uv-blue !important;
			}
		}
	}
	@include for-phone-only {
		display: flex;
		width: calc(100% + 30px);
		margin-left: -30px;
		bottom: -1px;
		background-color: $night;
		left: unset;
		transform: none;
		.cartButtonContainer {
			margin-top: 12px;
			margin-bottom: 14px;
			width: calc(100% - 60px);
			.addButton {
				justify-content: space-between;
				padding: 0px 24px !important;
				border-color: $uv-blue;
			}
		}
	}
}