@import "@ph/common/styles/main";
.productContainer {
	@extend .border-2;
	// margin-bottom: 28px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.metaContainer {
		padding: 24px 30px;
		background-color: #f5f1f0;
		display: flex;
		flex-direction: column;
		height: 100%;
		flex: 1;
		@extend .border-bottom-2;
		.text {
			@extend .body-txt-lg;
		}
		@include for-phone-only {
			padding: 24px 16px;
		}
	}
	.product {
		min-height: 200px;
		padding: 24px 30px;
		display: flex;
		// flex: 1;
		height: 100%;
		justify-content: space-between;
		// height: calc(100% - 127px);

		@include for-phone-only {
			padding: 24px 16px;
			height: auto;
		}
		.image {
			background-color: #f5f1f0;
			width: 100%;
			height: 100%;
			max-height: 250px;
			min-height: 250px;
			object-fit: cover;

			@include for-phone-only {
				min-height: 160px;
				max-height: 160px;
			}
		}
		.productInfo {
			padding-left: 20px;
			.title {
				@extend .h4;
			}
			@include for-phone-only {
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				height: 100%;
				padding: 0px;
				padding-left: 12px;
			}
		}

		.priceContainer {
			margin: 10px 0px;
			@extend .center-flex;
			gap: 8px;
			width: 100%;
			justify-content: left;
			flex-wrap: wrap;
			.price {
				@extend .body-txt-lg;
				@extend .font-Neue-medium;
				color: var(--text-color);
			}
			.discountPrice {
				@extend .body-txt-lg;
				color: #2d292666;
				text-decoration: line-through;
			}
			@include for-phone-only {
				flex-direction: column;
				align-items: flex-start;
				gap: 0px;
				margin-top: 8px;
			}
		}
		.description {
			@extend .quiz-2;
		}

		&.expanded {
			background-color: $uv-blue-40;
			// .productInfo .title,
			// .description,
			// .priceContainer .price,
			// .priceContainer .discountPrice,
			// .first {
			// 	color: $white
			// }
			.buttonContainer .addButton {
				background-color: white;
				border-color: $white;
				color: $uv-blue;
			}
		}
	}

	.tagContainer {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		margin-top: 20px;
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.buttonContainer {
		margin-top: 20px;
		@extend .center-flex;

		justify-content: space-between;
		&.end {
			justify-content: end;
		}
		.addButton {
			@extend .body-txt-md;
			@extend .center-flex;
			padding: 8px 30px;
			background-color: $white;
			border-radius: 42px;
			min-width: 200px;
			@extend .border-2;
			border-color: $night;
			cursor: pointer;
			color: $night;
			gap: 12px;

			&.checked {
				color: $white !important;
				background-color: $uv-blue !important;
				border-color: $uv-blue !important;
			}

			@include for-phone-only {
				width: calc(100%);
			}
		}
		@include for-phone-only {
			flex-direction: column-reverse;
			gap: 16px;
		}
	}
	.first {
		text-decoration: underline;
		@extend .body-txt-md;
		cursor: pointer;
	}

	.mobileDesc {
		margin-top: 18px;
	}
	.buttonCol {
		align-self: flex-end;
		@include for-phone-only {
			align-self: unset;
		}
	}
}

.checkbox {
	:global {
		.ant-checkbox {
			.ant-checkbox-inner {
				width: 30px;
				border: none;
				border-radius: 0px;
				height: 30px;
				&:after {
					display: inline-block;
					width: 30px;
					height: 30px;
					border: 2px solid $night;
					background: $white;
					opacity: 1;
					transform: rotate(0deg) scale(1) translate(-20%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				width: 30px;
				border: 1px solid $white;
				border-radius: 0px;
				height: 30px;
				&:after {
					content: "";
					display: table;
					width: 9px;
					height: 17px;
					border: 2px solid $white;
					border-top: none;
					border-left: none;
					border-inline-start: 0;
					opacity: 1;
					position: absolute;
					inset-inline-start: 21.5%;
					top: 46%;
					left: 24%;
					box-sizing: border-box;
					transform: rotate(45deg) scale(1) translate(-50%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
					background: none;
					border-color: $white;
				}
			}
			&:after {
				border-color: $white !important;
			}
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: transparent !important;
			border-color: $white !important;
			border: 2px solid $white;
		}
		:global(.ant-checkbox-inner) {
			&:hover {
				background-color: transparent !important;
				border-color: $night !important;
			}
		}
	}
	&:hover {
		background-color: transparent !important;
	}
}

.checkboxExpanded {
	:global {
		.ant-checkbox {
			.ant-checkbox-inner {
				width: 30px;
				border: none;
				border-radius: 0px;
				height: 30px;
				&:after {
					display: inline-block;
					width: 30px;
					height: 30px;
					border: none;
					background: $uv-blue;
					opacity: 1;
					transform: rotate(0deg) scale(1) translate(-20%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				width: 30px;
				border: 1px solid $night;
				border-radius: 0px;
				height: 30px;
				&:after {
					content: "";
					display: table;
					width: 9px;
					height: 17px;
					border: 2px solid $white;
					border-top: none;
					border-left: none;
					border-inline-start: 0;
					opacity: 1;
					position: absolute;
					inset-inline-start: 21.5%;
					top: 46%;
					left: 24%;
					box-sizing: border-box;
					transform: rotate(45deg) scale(1) translate(-50%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
					background: none;
					border-color: $white;
				}
			}
			&:after {
				border-color: $white !important;
			}
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: transparent !important;
			border-color: $white !important;
			border: 2px solid $white;
		}
		:global(.ant-checkbox-inner) {
			&:hover {
				background-color: transparent !important;
				border-color: $night !important;
			}
		}
	}
	&:hover {
		background-color: transparent !important;
	}
}

.triangleContainer {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -50px;
	z-index: 1;
	.triangle {
		width: 30px;
		height: 50px;
		border-top: solid 40px $uv-blue-40;
		border-left: solid 20px transparent;
		border-right: solid 20px transparent;
	}
}

.includedCol {
	min-height: 30px;
}

.includedContainer {
	position: absolute;
	left: 0;
	bottom: 0;
	width: fit-content;
	color: $night;
	cursor: pointer;
	@extend .center-flex;
	.icon {
		background-color: $night;
		padding: 8px;
		height: 34px;
		width: 35px;
		svg {
			width: 100%;
			height: 100%;
		}
	}
	.text {
		background-color: $uv-blue-40;
		padding: 9px 14px;
		@extend .note-1;
		@extend .font-Mono-medium;
		text-transform: uppercase;
	}
}
