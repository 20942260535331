@import "../../styles/main";

$desktop-height: calc(100vh - $protocol-header-md-height);
.tabs_heading {
	writing-mode: vertical-rl;
	text-orientation: mixed;
}
.protocolContainer {
	background: $secondary-color;
	height: $desktop-height;
	overflow: hidden;
	overflow-y: scroll;
	max-height: 96vh;

	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;
	-ms-overflow-style: none;

	.protocolDetail {
		padding: 40px 60px;
		@include for-both-phone-and-tablet {
			padding: 20px 30px;
		}
		.title {
			@extend .text-uv-blue;
			@extend .font-NeueHGDP-light;
			margin-bottom: 0px;
		}
		.ratingContainer {
			margin-bottom: 10px;
		}
		.headContainer {
			padding: 0px !important;
		}
	}
	.protocolImg {
		width: 100%;
		height: 50vh;
		background-color: $stone;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.protocolDesc {
		@extend .body-txt-lg;
		@extend .text-uv-blue;
		margin-top: 30px;
		* {
			color: $uv-blue;
			font-size: $body-text-lg !important;
		}
		strong {
			font-weight: 600;
			font-weight: bold;
		}
		@include for-both-phone-and-tablet {
			margin-bottom: 24px;

			* {
				color: $uv-blue;
				font-size: $body-text-mobile-lg !important;
			}
		}
	}

	@include for-both-phone-and-tablet {
		height: auto;
		max-height: none;
	}
}
.protocolSliderContainer {
	height: $desktop-height;
	@include for-both-phone-and-tablet {
		height: auto;
	}
}
.protocolTabs {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: flex-start;
	align-items: stretch;

	.tabsContent {
		position: relative;
		overflow: hidden;
		width: 0%;
		flex: 0 auto;
		background-color: #f5f1f0;
		transition: height 400ms ease, width 400ms ease;

		&.active {
			width: 100%;
			box-shadow: 4px 0px 5px #00000033 inset;
			overflow: visible;
			@include for-both-phone-and-tablet {
				box-shadow: none;
				overflow: visible;
			}
		}

		@include for-phone-only {
			height: 100vh;
			overflow-y: scroll;
		}

		.tabWrap {
			position: absolute;
			left: 0%;
			top: 0%;
			right: 0%;
			bottom: 0%;
			display: flex;
			width: 100%;
			height: 100%;
			padding: 2.8em;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
			}

			scrollbar-width: none;
			-ms-overflow-style: none;

			@media (max-width: 1440px) {
				padding: 2.8em 2em;
				padding-right: 0px;
			}

			.tabContain {
				width: 32vw;
				margin-bottom: 100px;

				@media (max-width: 1540px) {
					width: 29vw;
				}
				@media (max-width: 1280px) {
					width: 27vw;
				}
				@media (max-width: 990px) {
					width: calc(100vw - 80px);
				}
				@include for-phone-only {
					width: calc(100vw - 80px);
					margin-bottom: 0px;
				}
			}
			@include for-both-phone-and-tablet {
				padding: 2em 0px 2em 24px;
				align-items: self-start;
				width: 100%;
				background: $stone;
				height: auto;
				overflow-y: unset; //New change for ios
				position: relative; //New change for ios
			}
		}
	}

	.tabsContent.active:nth-of-type(1) {
		box-shadow: none;
	}

	.tabsLink {
		display: flex;
		padding: 2.3em 1.5em;
		flex-direction: row;
		justify-content: center;
		flex: 0 0 auto;
		min-width: 80px;
		box-shadow: inset 4px 0px 10px -5px rgba(0, 0, 0, 0.5);
		background-color: #f5f1f0;
		transition: all 400ms ease;

		@media (max-width: $screen-lg-min) {
			display: none;
		}
		&.active {
			box-shadow: none;
			border-left: none;
			background-color: #f5f1f0;
		}
		.tabsHeading {
			margin-right: 0px;
			margin-left: 0px;
			transform: rotate(-180deg);
			font-size: 1.9em;
			font-weight: 700;
		}

		.protocolNavContainer {
			position: relative;

			.circleContainer {
				position: absolute;
				left: -29px;
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.protocolHeading {
				writing-mode: vertical-rl;
				text-orientation: mixed;
				color: #2e5acc;
				margin-right: 0px;
				margin-left: 0px;
				margin-top: 42px;
				transform: rotate(-180deg);
				@extend .note-1;
				@extend .font-Mono-medium;
			}

			.halfCircle {
				width: 92px;
				height: 49px;
				border-top-left-radius: 100px;
				border-top-right-radius: 100px;
				background-color: $uv-blue;
				transform: rotate(-90deg);
			}
			.text {
				@extend .note-1;
				@extend .font-Mono-medium;
				position: absolute;
				color: #fff;
				top: 34%;
				left: 40%;
				transform: translate(-50%, -50%);
				transform: rotate(90deg);

				.icon {
					width: 18px;

					@include for-both-phone-and-tablet {
						width: 12px;
					}
				}
				img {
					width: 18px;
				}
			}
		}
	}

	.tabsLink:nth-of-type(1) {
		box-shadow: none;
	}
}

.inlineBlock {
	max-width: 100%;
	display: inline-block;
}

.verticleNavContainer {
	display: none;
	position: relative;

	@media (max-width: $screen-lg-min) {
		display: inline-block;
	}

	.circleContainer {
		position: absolute;
		left: auto;
		right: -26px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.protocolHeading {
		writing-mode: vertical-rl;
		text-orientation: mixed;
		color: #2e5acc;
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 42px;
		transform: rotate(-180deg);
		@extend .note-1;
		@extend .font-Mono-medium;
	}

	.halfCircle {
		width: 70px;
		height: 45px;
		border-top-left-radius: 100px;
		border-top-right-radius: 100px;
		background-color: $uv-blue;
		opacity: 0.2;
		transform: rotate(-90deg);

		&.active {
			opacity: 1;
		}
	}
	.text {
		@extend .note-1;
		@extend .font-Mono-medium;
		position: absolute;
		color: #fff;
		top: 34%;
		left: 40%;
		transform: translate(-50%, -50%);
		transform: rotate(90deg);
		@include for-phone-only {
			top: 26%;
		}
	}
}

.productsContainer {
	// min-height: 48vh;
	// max-height: 57vh;
	// overflow-y: scroll;
	display: flex;
	flex-direction: column;
	align-items: baseline;
	width: 100%;
	margin-top: 24px;

	@include for-phone-only {
		min-height: unset;
		margin-top: 20px;
	}

	.product {
		background-color: $white;
		padding: 18px 18px 14px 18px;
		border-left: 10px solid transparent;
		width: 100%;
		cursor: pointer;
		margin-top: 24px;
		position: relative;

		&.selected {
			border-left: 10px solid $uv-blue !important;
			@include for-both-phone-and-tablet {
				border-left: 6px solid $uv-blue !important;
			}
		}
		&:first-child {
			margin-top: 0px;
		}
		&:hover {
			border-left: 10px solid $uv-blue-40;
			@include for-both-phone-and-tablet {
				border-left: 6px solid $uv-blue-40;
			}
		}

		@include for-both-phone-and-tablet {
			border-left: 6px solid transparent !important;
			margin-top: 20px;
			padding: 16px 10px 16px 10px;
		}

		.productDetail {
			display: flex;
			flex-direction: column;
			gap: 12px;
			padding-right: 16px;

			@include for-both-phone-and-tablet {
				gap: 8px;
				padding-right: 10px;
			}

			&.topSection {
				margin-bottom: 8px;
				flex-direction: row;
				gap: 2px;
			}

			.productPrice {
				display: inline;
				color: $uv-blue;
				// text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-bold;
				min-height: 16px;
			}
			.discountPrice {
				display: inline;
				margin-right: 8px;
				color: $uv-blue-40;
				text-decoration: line-through;
				// text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-bold;
			}
			.productTitle {
				color: $uv-blue;
				text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-medium;
			}
			.productDesc {
				color: $uv-blue;
				@extend .note-2;
				@extend .font-Mono-medium;
				* {
					@extend .font-Mono-medium;
					letter-spacing: 0px;
				}
				// @include for-both-phone-and-tablet {
				// 	font-family: "PPNeueMontrealMono Book" !important;
				// }
			}

			.addButton {
				color: $uv-blue !important;
				border: 1px solid $uv-blue;
				min-width: 110px;
				height: 36px !important;
				padding: 8px;

				&.selected {
					background: $uv-blue !important;
					color: $white !important;
				}
			}
		}

		.productImage {
			background-color: transparent;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			max-height: 150px;

			@include for-desktop-up {
				max-height: 160px;
			}

			@include for-big-desktop-up {
				max-height: 210px;
			}
		}

		.productImage img {
			width: 100%; /* Ensure the image stretches to fit the width */
			height: 100%; /* Ensure the image stretches to fit the height */
			object-fit: cover; /* Maintain aspect ratio while filling the container */
			max-width: 100%; /* Ensure the image doesn't exceed the container width */
			max-height: 100%;
		}
		.imageFillMobile img {
			object-fit: fill;
			@include for-both-phone-and-tablet {
				object-fit: fill;
			}
		}
	}

	.productMultiple {
		background-color: $white;
		width: 100%;
		margin-top: 24px;
		border-left: 10px solid transparent;
		cursor: pointer;

		@include for-both-phone-and-tablet {
			border-left: 6px solid transparent;
		}

		&.selected {
			border-left: 10px solid $uv-blue !important;
			@include for-both-phone-and-tablet {
				border-left: 6px solid $uv-blue !important;
			}
		}

		&:hover {
			border-left: 10px solid $uv-blue-40;
			@include for-both-phone-and-tablet {
				border-left: 6px solid $uv-blue-40;
			}
		}

		.productDetail {
			display: flex;
			flex-direction: column;
			padding: 18px 18px 18px 14px;
			gap: 12px;

			@include for-both-phone-and-tablet {
				padding: 18px 18px 18px 10px;
			}

			.productPrice {
				display: inline;
				color: $uv-blue;
				// text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-bold;
			}
			.discountPrice {
				display: inline;
				margin-right: 8px;
				color: $uv-blue-40;
				text-decoration: line-through;
				// text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-bold;
			}
			.productTitle {
				color: $uv-blue;
				text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-medium;
			}
			.productDesc {
				color: $uv-blue;
				@extend .note-2;
				@extend .font-Mono-medium;
				* {
					@extend .font-Mono-medium;
					letter-spacing: 0px;
				}
			}
		}
		.productSelect {
			padding: 18px 18px 14px 18px;
			border-top: 1px solid $stone;
			// border-left: 10px solid $uv-blue;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			@include for-both-phone-and-tablet {
				padding: 10px 10px 8px 10px;
			}

			.productTitle {
				color: $uv-blue;
				text-transform: uppercase;
				@extend .note-1;
				@extend .font-Mono-medium;
			}

			.addButton {
				color: $uv-blue !important;
				border: 1px solid $uv-blue;
				height: 36px !important;
				padding: 8px !important;
				min-width: 110px;

				&.selected {
					background: $uv-blue !important;
					color: $white !important;
				}
			}
		}
	}
}

.cartContainer {
	background-color: $white;
	width: 100%;
	height: auto;

	.cartItem {
		padding: 20px 0px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid $uv-blue-40;
		margin: 0px 30px;


		@include for-phone-only {
			padding: 14px 0px;
			// padding-left: 20px;
			margin: 0px 16px;
		}

		&.md03Item {
			border-top: none;
			padding: 0px;
			margin-left: 0px;
			margin-right: 0px;
		}

		&.first {
			border-top: none !important;
		}

		&.subCartItem {
			margin-left: 40px;
			padding-left: 0px;
			padding-top: 12px;
			// padding: 10px 20px !important;
			@include for-phone-only {
				margin-left: 28px;
				padding-top: 6px;
			}
		}

		.cartTitle {
			color: $night;
			@extend .body-txt-lg;
			@extend .font-Neue-medium;
			@media (max-width: 1440px) {
				font-size: $note-1-text;
				line-height: $note-1-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-1-text-mobile;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemTitle,
		.itemPrice,
		.discountPrice {
			color: $uv-blue;

			@extend .note-1;
			@extend .font-Mono-medium;
			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
			&.bold {
				@extend .font-Mono-bold;
			}
		}
		.itemTitle {
			width: 75%;
			text-transform: uppercase;
			@extend .font-Mono-regular;
			svg {
				margin-left: 16px;
			}

			@include for-phone-only {
				width: 79%;
				svg {
					margin-left: 4px;
					width: 14px;
				}
			}
			@media (max-width: 366px) {
				width: 100%;
			}
			@media (max-width: 370px) {
				font-size: 10px;
				display: flex;
				align-items: center;
				svg {
					margin-left: 3px;
					width: 12px;
				}
			}
		}

		// .itemPrice,
		// .discountPrice {
		// 	@extend .font-Mono-bold;
		// }

		.subItemTitle {
			color: $uv-blue;
			@extend .note-1;
			@extend .font-Mono-medium;
			width: 80%;

			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}

			@include for-both-phone-and-tablet {
				
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
		}
		.discountPrice {
			color: $uv-blue-40;
			text-decoration: line-through;
			@media (max-width: 1290px) {
				display: flex;
				justify-content: flex-end;
			}
		}
		.itemPrice {
			display: flex;
			gap: 0px;
			flex-wrap: wrap;
			justify-content: end;
		}

		.redDiscountPrice,
		.redItemPrice {
			font-style: italic;
			@extend .note-1;
			@extend .font-PP-italic;
			text-transform: none;
			color: $dusty;
			margin-left: 8px;

			@media (max-width: 1440px) {
				font-size: $note-2-text;
				line-height: $note-2-line-height;
			}
			@include for-both-phone-and-tablet {
				font-size: $note-2-text-mobile;
				line-height: $note-2-line-height-mobile;
			}
			
		}
		.redDiscountPrice {
			text-decoration: line-through;
		}
	}
	.subTotal {
		padding: 4px 28px 30px 28px;
		background: #d5def5;

		.line {
			padding-bottom: 24px;
			border-top: 2px solid $night;
		}

		.disclaimer {
			display: inline-flex;
			gap: 8px;
			.icon {
				@extend .body-txt-lg;
				line-height: 17px;
				color: $night;
				margin-right: 6px;
			}
			.text {
				@extend .body-txt-md;
				color: $night;
			}
		}

		.subItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 6px 0px;

			.itemTitle,
			.itemPrice {
				color: $night;
				// text-transform: capitalize;
				@extend .note-1;
				@extend .font-Neue-medium;
			}
			.itemTitle {
				@extend .font-Mono-regular;
			}
		}

		@include for-phone-only {
			padding: 0px 16px 20px 16px;

			.line {
				padding-bottom: 16px;
			}
		}
	}

	.total {
		padding: 24px 28px 14px 28px;
		// border-left: 10px solid $uv-blue;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #d5def5;

		.itemTitle,
		.itemPrice,
		.discountPrice {
			color: $night;
			@extend .h6;
			@media (max-width: 1440px) {
				font-size: $body-text-mobile-lg;
				line-height: $note-1-line-height-mobile;
			}
		}
		.itemTitle,
		.itemPrice {
			@extend .font-Neue-medium;
		}

		.discountPrice {
			// color: $uv-blue-40;
			margin-right: 10px;
			text-decoration: line-through;
			color: #767472;
			// @media (max-width: 1290px) {
			// 	display: flex;
			// 	justify-content: flex-end;
			// }
		}

		@include for-phone-only {
			padding: 20px 16px 20px 16px;
		}
	}

	.customCollapse {
		border: none;
		box-shadow: none;
		background-color: $white;
		// border-top: 1px solid $stone;
		border-bottom: none;
		border-radius: 0px;

		:global(.ant-collapse-header) {
			padding: 20px 0px !important;
			border-top: 1px solid $uv-blue-40;
			margin-left: 22px;
			margin-right: 30px;

			align-items: center !important;

			@include for-phone-only {
				padding: 16px 0px !important;
				margin-left: 12px;
    			margin-right: 16px;
			}
		}
		:global(.ant-collapse-item) {
			border: none;
			box-shadow: none;
			border-left: 8px solid transparent;

			@include for-phone-only {
				border-left: 4px solid transparent;
			}
		}
		:global(.ant-collapse-content) {
			border-top: none;
		}
		:global(.ant-collapse-expand-icon) {
			display: none !important;
			img {
				display: none !important;
			}
		}
		:global(.ant-collapse-content-box) {
			padding: 0px !important;

			div:first {
				border-top: none !important;
			}
		}
		:global(.ant-collapse-item-active) {
			border-left: 8px solid $uv-blue;
			border-radius: 0px;
			@include for-phone-only {
				border-left: 4px solid $uv-blue;
			}
		}
		:global(.ant-collapse-content-box) {
			padding: 0px !important;

			div {
				border-top: none !important;
			}
		}
	}
}

.cartButtonContainer {
	margin-top: 24px;
	margin-bottom: 24px;
	background-color: $stone;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	.backButton {
		cursor: pointer;
		width: 54px;
	}
	.addButton {
		width: 100%;
		background: $uv-blue !important;
		display: flex;
		align-items: center;
		justify-content: end;

		span {
			@extend .font-Neue-medium;
		}

		&:hover {
			background: $uv-blue !important;
		}
	}
}

.incrementButton {
	padding: 0px 4px;
	height: 30px !important;
	display: flex;
	align-items: center;
	width: fit-content;
	position: absolute;
	top: 0;
	right: 30px;
	z-index: 999;
	background-color: #d5def5;
	@include for-both-phone-and-tablet {
		padding: 0px 7px;
	}
	.minusButton {
		box-shadow: none;
		background: transparent !important;
		padding: 0px 0px !important;
		height: 100% !important;
	}
	.count {
		@extend .note-1;
		@extend .font-Mono-regular;
		letter-spacing: 1px;
		padding: 0px 6px;
		padding-top: 3px;
		color: $uv-blue;
		@include for-phone-only {
			padding: 0px 0px;
		}
	}
	.buttonIcon {
		color: $uv-blue;
		font-size: 14px;
	}
	.plusButton {
		box-shadow: none;
		background: transparent !important;
		padding: 0px 0px !important;
		height: 100% !important;
	}
}

.checkbox {
	position: absolute;
	top: 0;
	right: 0;

	&.relative {
		position: relative;
	}

	:global {
		.ant-checkbox {
			.ant-checkbox-inner {
				width: 30px;
				border: none;
				border-radius: 0px;
				height: 30px;
				&:after {
					display: inline-block;
					width: 30px;
					height: 30px;
					border: none;
					background: linear-gradient(#fff 0 0),
						linear-gradient(#fff 0 0), #d5def5;
					background-position: center;
					background-size: 50% 2px, 2px 50%; // thickness = 2px, length = 50% (25px)
					background-repeat: no-repeat;
					opacity: 1;
					transform: rotate(0deg) scale(1) translate(-20%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
				}
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				width: 30px;
				border: 1px solid $uv-blue; // Ensure $uv-blue is defined somewhere in your SCSS
				border-radius: 0px;
				height: 30px;
				&:after {
					content: ""; // Required for :after to display
					display: table;
					width: 9px;
					height: 17px;
					border: 2px solid #fff;
					border-top: 0;
					border-inline-start: 0;
					opacity: 1;
					position: absolute;
					inset-inline-start: 21.5%;
					top: 46%;
					left: 24%;
					box-sizing: border-box;
					transform: rotate(45deg) scale(1) translate(-50%, -50%);
					transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
						0.1s;
					background: none;
				}
			}
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: $uv-blue !important;
			border-color: $uv-blue !important;
		}
	}
}

.stepStartLabel {
	@extend .text-uv-blue;
	@extend .font-Mono-medium;
	@extend .note-1;
	margin-bottom: 10px;
}

.buttonBox {
	position: absolute;
	background-color: #f5f1f0;
	// width: 32vw;
	// margin-top: 60px;
	padding: 16px 0px;
	// width: 40vw;
	// margin-left: 53px;
	z-index: 2;
	width: calc(100% + 80px);
	align-items: center;
	justify-content: center;

	// width: 40vw;
	// margin-left: -54px;
	// padding: 20px 51px;
	box-shadow: 4px 0px 20px 10px #0000000d;

	bottom: 0;

	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 32vw;
		margin-left: -4vw;

		&:not(.showButton) {
			justify-content: flex-end;
		}

		.backButton {
			cursor: pointer;
			width: 54px;
		}

		.nextButton {
			background: $uv-blue;

			img {
				margin-left: 20px;
			}
			&:hover {
				background: $uv-blue !important;
			}
		}
		@media (max-width: 1540px) {
			width: 29vw;
		}
		@media (max-width: 1280px) {
			width: 27vw;
		}
		@media (max-width: 1100px) {
			width: 27vw;
		}
		@include for-phone-only {
			width: 100%;
			margin-left: 24px;
			margin-right: 50px;
		}
	}

	@include for-phone-only {
		position: unset;
		// margin-top: 50px; //New change for ios
		// padding-bottom: 24px;//New change for ios
		width: 100vw;
	}
}

.priceContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	flex-wrap: wrap;
	justify-content: end;
}

.productCollapse {
	:global(.ant-collapse-item) {
		:global(.ant-collapse-header) {
			display: flex;
			align-items: center;
			justify-content: space-between;

			:global(.ant-collapse-expand-icon) {
				order: 2;
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}
